<template>
    <MetaListTable
        class="statistic-rank"
        :showTableHeader="false"
        :table-data="chartData.data"
        :style="{'--minWidth':minWidth}"
    >
      <el-table-column
          v-for="(item,index) in chartData.chartProps"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.minWidth"
          :width="item.width"
          :sortable="item.sortable"
          :show-overflow-tooltip="item.showOverflowTooltip"
      ></el-table-column>

    </MetaListTable>
</template>

<script>
import MetaListTable from "../list/MetaListTable";
import {mockData} from "./js/mockDataAPI";
import {CHART_TYPES} from "./js/statisticChartConfig";

export default {
  name: "StatisticRank",
  components: {MetaListTable},
  props: {
    chartData: {
      default: mockData[CHART_TYPES.TABLE],
    },
    minWidth:{
      type: [Number,String],
      default: 'unset'
    },

  }
}
</script>

<style scoped>
.statistic-rank{
  min-width: var(--minWidth,unset);
}
</style>
